let TeamPerformance = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

   
    /**
         * 获取团队绩效列表
         * @param pageIndex
         * @param keyWord
         * @param success
         * @param error
         * @constructor
         */
    this.GetTeamPerformance = function (keyWord,  pageIndex, success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/List'
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取团对绩效列表
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
     this.GetTeamPerformanceById = function (commanderId,  year, success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/Cycle/'+commanderId+'/'+year
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 根据绩效发放计算
     * @param commanderId
     * @param cycleId
     * @param success
     * @param error
     * @constructor
     */
    this.GetTeamPerformanceCalculation = function (commanderId, cycleId, success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/Calculation/'+commanderId+'/'+cycleId
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 根据绩效发放
     * @param commanderId
     * @param cycleId
     * @param success
     * @param error
     * @constructor
     */
    this.GetTeamPerformanceGrant = function (commanderId, cycleId, success, error) {
        var item = {
            commanderId: commanderId,
            cycleId:cycleId
        }
        var url = ServiceBaseAddress + '/api/TeamPerformance/Grant/'+commanderId+'/'+cycleId
        TokenClient.Post(
            url,
            true,
            { 'Content-Type': 'application/json' },
            null,
            function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
     /**
      * 获取绩效周期信息
      * @param {*} performanceRewardCycle 123月季年
      * @param {*} success 
      * @param {*} error 
      */
    this.GetTeamPerformanceInfo = function (performanceRewardCycle,success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/Verify/'+performanceRewardCycle
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    /**
     * 获取团长绩效发放详情
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.GetTeamPerformanceGrantDetail = function (commanderId,cycleId,keyWord,success, error) {
        var url = ServiceBaseAddress + '/api/TeamPerformance/GrantDetail/'+commanderId+'/'+cycleId
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
    

}

export { TeamPerformance }
