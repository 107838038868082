<template>
    <div class="height1">
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入名称或手机号"
                                    @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <el-descriptions title="" direction="vertical" border :column="4">
                                    <el-descriptions-item label="团长">
                                        {{tableData[0].teamPerformanceCycle.commander.userName}}-{{tableData[0].teamPerformanceCycle.commander.phone}}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="团总交易金额(元)">
                                        {{tableData[0].teamPerformanceCycle.totalConsumeAmount}}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="团总绩效奖励金额(元)">
                                        {{tableData[0].teamPerformanceCycle.rewardAmount}}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="团总人数">
                                        {{tableData[0].teamPerformanceCycle.teamMembers}}
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <el-table :data="tableData"  height="calc(100% - 150px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="targetUser.userName" label="团员" width="150" align="center">
                    </el-table-column>
                    <el-table-column prop="targetUser.phone" label="团员账号" align="center">
                    </el-table-column>
                    <el-table-column prop="consumeAmount" label="消费金额(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="grantRatio" label="奖励比例" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.grantRatio }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rewardAmount" label="获得奖励(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="grantTime" label="发放时间" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.teamPerformanceCycle.grantTime | timefilters }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { TeamPerformance } from "../../../components/HospitalDomain/TeamPerformance";
export default {
    name: "Bill",
    components: {
        bread
    },
    data() {
        var temPerformance = new TeamPerformance(this.TokenClient, this.Services.Authorization);
        return {
            TeamPerformanceDomain: temPerformance,
            tableData: [
                {
                    teamPerformanceCycle:{}
                }
            ],
            commanderId: this.$route.query.commanderId,
            formLabelWidth: '150px',
            teamPerformanceInfo: {
                isTeamPerformanceReward: false,
                performanceRewardCycle: 1,
                performanceRewardScale: []
            },
            performanceRewardCycleName: '',
            cycleId: this.$route.query.cycleId,
            searchForm: {
                keyWord: '',
            },
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        search(event) {
            this.getList()
        },
        compareDate(endTime) {
            var date = new Date();
            //2把字符串格式转换为日期类
            var startTime = new Date(Date.parse(date));
            var endTime = new Date(Date.parse(endTime));

            //进行比较
            return (startTime > endTime);
        },
        getList() {
            var _this = this;
            _this.TeamPerformanceDomain.GetTeamPerformanceGrantDetail(_this.commanderId, _this.cycleId, _this.searchForm.keyWord,
                function (data) {
                    _this.tableData = data.data;
                },
                function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
.el-input {
    width: 95%;
}

.table-top {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-form-item {
    margin-bottom: 10px;
}

.iq-search-bar /deep/.el-descriptions__header {
    margin-bottom: 10px;
}
</style>
